import { mapGetters } from 'vuex'
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isExpandable: {
      type: Boolean,
      default: true
    },
    isEligible: Boolean
  },
  data() {
    return {
      activeState: false
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile'])
  },
  mounted() {
    this.activeState = this.isActive
  },
  methods: {
    toggle() {
      if (this.isEligible) {
        this.activeState = !this.activeState
      }
    }
  }
}
